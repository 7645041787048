var __jsx = React.createElement;
import React, { useEffect, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Heading, LoadingLogo, LoadingLogoType, PageFlow, PageLoader, Paragraph, ProcessInfo, ProcessStatus, SpacingEnum, StatusIcon, Wrapper } from '@yieldstreet/ui-kit';
import { InvestorEntityProvider } from '@yieldstreet/platform-kit';
import { PROVIDER_CONFIG, useConsentDisclosureVersions, useFlags } from '@yieldstreet/tool-kit';
import { SParagraph, SupportLink } from './ETCKYCContainer.style';
import { ETCKYC } from '../ETCKYC';
import { useETCKYC } from '../hooks/useETCKYC';
import { testIds } from './ETCKYCContainer.model';
export const ETCKYCContainer = () => {
  var _PROVIDER_CONFIG$Inve;
  const form = useRef(null);
  const history = useHistory();
  const match = useRouteMatch();
  const {
    useEtcOpenAccountV3: newEtcAccountOpeningForm
  } = useFlags({
    useEtcOpenAccountV3: false
  });

  /**
   * If has investorEntityId, it's coming from ETC
   * No investorEntityId = creating IRA Entity on YS platform
   */
  const {
    investorEntityId,
    iraType
  } = match.params;
  const providerCompany = (_PROVIDER_CONFIG$Inve = PROVIDER_CONFIG[InvestorEntityProvider.ETC]) === null || _PROVIDER_CONFIG$Inve === void 0 ? void 0 : _PROVIDER_CONFIG$Inve.company;

  /**
   * Load important data to use in the form
   */
  const {
    stringifiedVersions,
    isLoading: isLoadingDisclosureVersions
  } = useConsentDisclosureVersions();
  const {
    isLoading,
    isError,
    getEtcOccupationsList,
    etcOccupationsList
  } = useETCKYC({
    investorEntityId
  });
  const showLoader = isLoadingDisclosureVersions || isLoading;
  useEffect(() => {
    if (!investorEntityId && !etcOccupationsList && !isLoading && !isError && newEtcAccountOpeningForm) {
      getEtcOccupationsList();
    }
  }, [etcOccupationsList, getEtcOccupationsList, investorEntityId, isError, isLoading, newEtcAccountOpeningForm]);
  const renderLoader = newEtcAccountOpeningForm && !investorEntityId ? __jsx(ProcessInfo, {
    title: "Getting your account set up",
    iconCentered: true,
    icon: __jsx(LoadingLogo, {
      size: {
        width: '100px',
        height: '100px'
      },
      type: LoadingLogoType.NO_LOGO
    }),
    testId: testIds.loading.id
  }, __jsx(Paragraph, null, "This may take a few seconds.")) : __jsx(PageLoader, {
    testId: testIds.loading.id
  });
  const renderError = __jsx(ProcessInfo, {
    title: "Unfortunately, there was a problem setting up your account",
    iconCentered: true,
    icon: __jsx(StatusIcon, {
      status: ProcessStatus.WARNING,
      testId: testIds.errorIcon.id
    }),
    testId: testIds.error.id
  }, __jsx(Paragraph, null, "Please try again in a few minutes. If you\u2019re still experiencing problems setting up your IRA account, please", __jsx(SupportLink, {
    id: "intercom-link"
  }, "contact our Investor Relations team for assistance"), "."));
  const renderForm = __jsx(React.Fragment, null, investorEntityId ? __jsx(React.Fragment, null, __jsx(Heading, {
    type: 4,
    marginBottom: SpacingEnum.l
  }, "Verify your identity"), __jsx(SParagraph, null, "Please enter your personal details so we can set up your IRA account with", ' ', providerCompany, ".")) : __jsx(React.Fragment, null, __jsx(Heading, {
    type: 4,
    marginBottom: SpacingEnum.l
  }, "Verify your identity"), __jsx(SParagraph, null, "To open your new IRA with ", providerCompany, ", please verify the following personal information.")), __jsx(ETCKYC, {
    form: form,
    investorEntityId: investorEntityId,
    iraType: iraType,
    occupationsList: etcOccupationsList,
    consentDisclosureVersion: stringifiedVersions
  }));
  return __jsx(PageFlow, {
    showLogo: false,
    showFooter: false,
    backNavigation: true,
    backNavigationCallback: () => history.goBack()
  }, __jsx(Wrapper, null, showLoader && !isError && renderLoader, !showLoader && isError && renderError, !showLoader && !isError && renderForm));
};