import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { investorEntities, investorEntity, address, aiqIncome, investorEntityStatus, InvestorEntityStatus, InvestorEntityActionCode, InvestorEntityProvider } from '@yieldstreet/platform-kit';
export const entityWithFullData = _objectSpread(_objectSpread({}, investorEntity), {}, {
  address: {
    addressLine1: address.addressLine1,
    addressLine2: '',
    city: address.city,
    state: address.state,
    postalCode: address.postalCode,
    countryCode: 'US',
    addressComplete: true
  },
  phoneNumber: '+19172222222'
});
export const entityWithDate = _objectSpread(_objectSpread({}, investorEntity), {}, {
  createdDate: '2023-10-26T15:02:51Z'
});
export const investorEntityFullData = _objectSpread(_objectSpread({}, investorEntities), {}, {
  entities: [entityWithFullData]
});
export const investorEntityWithDate = _objectSpread(_objectSpread({}, investorEntities), {}, {
  entities: [entityWithDate]
});
export const withIdentityConfirmed = {
  201924: _objectSpread(_objectSpread({}, investorEntityStatus), {}, {
    readyToInvest: true,
    investorEntityId: 201924
  })
};
export const withIdentityUnverified = {
  201924: _objectSpread(_objectSpread({}, investorEntityStatus), {}, {
    investorEntityId: 201924,
    identity: _objectSpread(_objectSpread({}, investorEntityStatus.identity), {}, {
      status: InvestorEntityStatus.MISSING,
      statusDisplay: 'Unverified'
    }),
    investorEntityAction: {
      code: InvestorEntityActionCode.SET_KYC_1,
      investorEntityId: null,
      status: null,
      nextStep: 'Complete set up',
      priority: 0
    }
  })
};
export const withMissingAccreditation = {
  201924: _objectSpread(_objectSpread({}, investorEntityStatus), {}, {
    investorEntityId: 201924,
    allPendingActions: [InvestorEntityActionCode.VERIFY_ACCREDITATION]
  })
};
export const withAccreditationInReview = {
  201924: _objectSpread(_objectSpread({}, investorEntityStatus), {}, {
    investorEntityId: 201924,
    allPendingActions: [InvestorEntityActionCode.EDIT_ACCREDITATION]
  })
};
export const investorEntityAccountLimitReached = _objectSpread(_objectSpread({}, investorEntities), {}, {
  entitiesLimitReached: true
});
export const verifiedAIQ = _objectSpread(_objectSpread({}, aiqIncome), {}, {
  verified: true
});
export const investorEntityETC = _objectSpread(_objectSpread({}, investorEntities), {}, {
  entities: [_objectSpread(_objectSpread({}, entityWithFullData), {}, {
    provider: {
      type: InvestorEntityProvider.ETC,
      data: null
    }
  })]
});
export const withIdentityPendingETC = {
  201924: _objectSpread(_objectSpread({}, investorEntityStatus), {}, {
    provider: _objectSpread(_objectSpread({}, investorEntityStatus.provider), {}, {
      status: InvestorEntityStatus.PENDING
    })
  })
};