var __jsx = React.createElement;
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { PageFlow, PageLoader, useModalContext } from '@yieldstreet/ui-kit';
import { useProviderInvestorEntityMigration } from '@yieldstreet/tool-kit';
import { BankAccountProvider, InvestorEntityStatus } from '@yieldstreet/platform-kit';
import { DEFAULT_ROUTE } from 'utils/auth';
import { useBankAccounts } from 'pages/investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { getInvestorEntityRoute, useRouteInvestorEntityId } from 'pages/investorEntity/utils';
import { KYC_MIGRATION_MODAL } from '../modal/KYCMigrationModal.model';
import { KYCMigrationPending, KYCMigrationFailed } from './components/KYCMigrationStates';
import { testIds } from './KYCMigrationBankLinking.model';
import { BANK_FLOW_LINK_A_BANK_ACCOUNT } from '../../../../../bankLinking/BankLinkingPageFlow.model';
export const KYCMigrationBankLinking = () => {
  var _bankAccounts$find;
  const investorEntityId = useRouteInvestorEntityId();
  const {
    bankAccounts
  } = useBankAccounts();
  const {
    isMigrationPending,
    isMigrationFinal,
    isMigrationEligible,
    kycDetails,
    bankLinkDetails
  } = useProviderInvestorEntityMigration(investorEntityId, {
    checkKYCMigrated: true
  });
  const {
    0: bankLinkDone
  } = useState(false);
  const history = useHistory();
  const {
    showModal
  } = useModalContext();
  const hasMicroDeposit = (_bankAccounts$find = bankAccounts.find(acc => acc.provider === BankAccountProvider.PLAID)) === null || _bankAccounts$find === void 0 ? void 0 : _bankAccounts$find.depositVerificationPending;
  useEffect(() => {
    if ((isMigrationFinal || isMigrationPending) && isMigrationEligible) {
      history.push(DEFAULT_ROUTE);
      showModal(KYC_MIGRATION_MODAL);
    }
  }, [history, showModal, isMigrationFinal, isMigrationPending, isMigrationEligible]);
  const migrationContent = useMemo(() => {
    if (!kycDetails || !bankLinkDetails || hasMicroDeposit) {
      return null;
    }
    switch (kycDetails.identity.status) {
      case InvestorEntityStatus.CONFIRMED:
        if (!bankLinkDone) {
          return history.push(getInvestorEntityRoute(BANK_FLOW_LINK_A_BANK_ACCOUNT, investorEntityId));
        }
        return null;
      case InvestorEntityStatus.PENDING:
        return __jsx(KYCMigrationPending, null);
      case InvestorEntityStatus.FAILED:
        return __jsx(KYCMigrationFailed, null);
      default:
        return null;
    }
  }, [bankLinkDetails, bankLinkDone, hasMicroDeposit, history, kycDetails]);
  return __jsx(PageFlow, {
    showLogo: false
  }, migrationContent ? migrationContent : __jsx(PageLoader, {
    testId: testIds.loader.id
  }));
};