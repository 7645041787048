import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _CircularProgress from "@mui/material/CircularProgress";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ActionCell, ActionType } from '@yieldstreet/ui-kit';
import { InvestorEntityStatus } from '@yieldstreet/platform-kit';
import { useInvestorEntityFlags, useManagedPortfolioEligibilityContent } from '@yieldstreet/tool-kit';
import { getInvestorEntityRoute } from 'pages/investorEntity/utils';
import { useDiscretionaryFlags } from 'hooks';
import { INVESTOR_ENTITY_MNGT_ROOT_PATH } from '../../ManagementContainer.model';
import { useInvestorEntityAction, useInvestorEntityActionHandler } from '../../utils';
import { testIds } from './EntityBlock.model';
import { PendingStatus } from './EntityBlock.style';
export const Action = _ref => {
  var _managedGate$action, _investorEntityStatus;
  let {
    investorEntity,
    allEntitiesStatus,
    refetch
  } = _ref;
  const history = useHistory();
  const onStepSuccess = useCallback(() => refetch(), [refetch]);
  const {
    showManagedPortfolio
  } = useDiscretionaryFlags();
  const gotoEntityDetails = useCallback(() => history.push(getInvestorEntityRoute(`${investorEntity.id}`, INVESTOR_ENTITY_MNGT_ROOT_PATH)), [history, investorEntity.id]);
  const investorEntityStatus = allEntitiesStatus[investorEntity.id];
  const {
    managedInvestorAccount
  } = useInvestorEntityFlags(investorEntity);
  const {
    gateContent: managedGate,
    isLoading: isManagedLoading
  } = useManagedPortfolioEligibilityContent({
    investorEntityId: investorEntity.id.toString()
  });
  const {
    nextAction: nextEntityAction
  } = useInvestorEntityAction({
    investorEntity,
    investorEntityStatus,
    onStepSuccess
  });
  const {
    handleOnActionClick
  } = useInvestorEntityActionHandler();

  // View Helpers
  const hasEntityAction = !!nextEntityAction;
  const entityReadyToInvest = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.readyToInvest;
  const hasManagedAction = showManagedPortfolio && !!managedInvestorAccount && !!(managedGate !== null && managedGate !== void 0 && (_managedGate$action = managedGate.action) !== null && _managedGate$action !== void 0 && _managedGate$action.cta);
  const hasMultipleActions = entityReadyToInvest && hasEntityAction && hasManagedAction;
  const isPendingThirdParty = (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus = investorEntityStatus.provider) === null || _investorEntityStatus === void 0 ? void 0 : _investorEntityStatus.status) === InvestorEntityStatus.PENDING;
  if (isManagedLoading) {
    return __jsx(_CircularProgress, {
      size: 28,
      color: "inherit"
    });
  }
  if (hasMultipleActions) {
    return __jsx(ActionCell, {
      testId: testIds.status.id,
      label: "Action Required",
      type: ActionType.ActionRequired,
      onClick: e => {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        gotoEntityDetails();
      }
    });
  }
  if (hasEntityAction) {
    return __jsx(ActionCell, {
      testId: testIds.status.id,
      label: nextEntityAction.title,
      type: ActionType.ActionRequired,
      onClick: e => {
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        handleOnActionClick({
          action: nextEntityAction === null || nextEntityAction === void 0 ? void 0 : nextEntityAction.action,
          link: nextEntityAction === null || nextEntityAction === void 0 ? void 0 : nextEntityAction.link,
          investorEntityId: investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id
        });
      }
    });
  }
  if (hasManagedAction) {
    var _managedGate$action2, _managedGate$action2$;
    return __jsx(ActionCell, {
      testId: testIds.status.id,
      label: ((_managedGate$action2 = managedGate.action) === null || _managedGate$action2 === void 0 ? void 0 : (_managedGate$action2$ = _managedGate$action2.cta) === null || _managedGate$action2$ === void 0 ? void 0 : _managedGate$action2$.text) || '',
      type: ActionType.ActionRequired,
      onClick: e => {
        var _managedGate$action3, _managedGate$action3$;
        e === null || e === void 0 ? void 0 : e.stopPropagation();
        (_managedGate$action3 = managedGate.action) === null || _managedGate$action3 === void 0 ? void 0 : (_managedGate$action3$ = _managedGate$action3.cta) === null || _managedGate$action3$ === void 0 ? void 0 : _managedGate$action3$.callback();
      }
    });
  }
  if (isPendingThirdParty) {
    return __jsx(PendingStatus, null, __jsx(_Typography, _extends({
      component: "p",
      variant: "caption",
      sx: {
        fontWeight: 500
      }
    }, testIds.status.attr), "Pending third party provider"));
  }
  return null;
};