import { InvestorEntityStatus, InvestorEntityStepProvider } from '@yieldstreet/platform-kit';
import { AppAction, appActionManager } from '../../../app-actions';
import { useInvestorEntity } from '../../../hooks';
export const getInvestorIdentityAppAction = (investorEntityStatus, managementStrategy) => {
  const {
    id: investorEntityId,
    identity
  } = investorEntityStatus;
  switch (identity.status) {
    case InvestorEntityStatus.MISSING:
      if (identity.provider === InvestorEntityStepProvider.ETC) {
        return appActionManager.getAction(AppAction.START_KYC_ETC, {
          investorEntityId
        });
      } else {
        return appActionManager.getAction(AppAction.START_KYC, {
          investorEntityId,
          managementStrategy
        });
      }
    case InvestorEntityStatus.PENDING:
    case InvestorEntityStatus.ACTION_NEEDED:
    case InvestorEntityStatus.FAILED:
    case InvestorEntityStatus.ATTENTION:
    case InvestorEntityStatus.UNKNOWN:
    case InvestorEntityStatus.INVALID:
      if (identity.provider === InvestorEntityStepProvider.ETC) {
        return appActionManager.getAction(AppAction.CONTINUE_KYC_ETC, {
          investorEntityId
        });
      } else {
        return appActionManager.getAction(AppAction.CONTINUE_KYC, {
          investorEntityId,
          managementStrategy
        });
      }
    default:
      return null;
  }
};
export const useInvestorIdentityAppAction = (investorEntityId, managementStategy) => {
  const {
    isLoading,
    investorEntityStatus
  } = useInvestorEntity(investorEntityId);
  const identityAppAction = investorEntityStatus && getInvestorIdentityAppAction(investorEntityStatus, managementStategy);
  return {
    isLoading,
    identityAppAction
  };
};