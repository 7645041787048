import { useInvestorEntityFlags } from '@yieldstreet/tool-kit';
import { InvestorEntityStatus } from '@yieldstreet/platform-kit';
import { copy } from '../../BankAccounts.model';
import { useRouteInvestorEntity } from '../../../../../../utils';
export const useGetEmptyMessage = () => {
  var _investorEntityStatus;
  const {
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const {
    isETCAccount
  } = useInvestorEntityFlags(investorEntity);
  if (isETCAccount) {
    return copy.etcAccount;
  }
  if (investorEntityStatus !== null && investorEntityStatus !== void 0 && (_investorEntityStatus = investorEntityStatus.identity) !== null && _investorEntityStatus !== void 0 && _investorEntityStatus.status && [InvestorEntityStatus.FAILED, InvestorEntityStatus.PENDING].includes(investorEntityStatus.identity.status)) {
    return copy.pendingKyc;
  }
  return copy.empty;
};