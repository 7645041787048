var __jsx = React.createElement;
import React from 'react';
import { Button, Paragraph, ProcessInfo, ProcessStatus, StatusIcon } from '@yieldstreet/ui-kit';
import { InvestorEntityStatus } from '@yieldstreet/platform-kit';
import { testIds } from '../KYCMigrationBankLinking.model';
import { copy } from './KYCMigrationStates.model';
export const KYCMigrationPending = () => {
  const c = copy[InvestorEntityStatus.PENDING];
  return __jsx(ProcessInfo, {
    testId: testIds.pendingInfo.id,
    icon: __jsx(StatusIcon, {
      status: ProcessStatus.PENDING,
      testId: testIds.icon.id
    }),
    title: c.title,
    buttons: __jsx(Button, {
      trackingId: testIds.contactIrButton.id,
      buttonType: "secondary",
      onClick: () => {
        var _Intercom, _ref;
        return (_Intercom = (_ref = window).Intercom) === null || _Intercom === void 0 ? void 0 : _Intercom.call(_ref, 'show');
      }
    }, c.cta)
  }, __jsx(Paragraph, {
    secondary: true,
    size: "small"
  }, c.text));
};
export const KYCMigrationFailed = () => {
  const c = copy[InvestorEntityStatus.FAILED];
  return __jsx(ProcessInfo, {
    testId: testIds.failureInfo.id,
    icon: __jsx(StatusIcon, {
      status: ProcessStatus.FAILURE,
      testId: testIds.icon.id
    }),
    title: c.title,
    buttons: __jsx(Button, {
      trackingId: testIds.contactIrButton.id,
      buttonType: "basic",
      onClick: () => {
        var _Intercom2, _ref2;
        return (_Intercom2 = (_ref2 = window).Intercom) === null || _Intercom2 === void 0 ? void 0 : _Intercom2.call(_ref2, 'show');
      }
    }, c.cta)
  }, __jsx(Paragraph, {
    secondary: true,
    size: "small"
  }, c.text));
};