var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PageFlow, PageLoader, Paragraph, ProcessStatus, StatusIcon, ProcessInfo, Button, ExternalProviderVisual, FormCheckboxInput, Wrapper, Notification, Markdown } from '@yieldstreet/ui-kit';
import { consentDisclosureText, useApiErrorMessage, useConsentDisclosureVersions } from '@yieldstreet/tool-kit';
import { InvestorEntityStatus, InvestorEntityTypeValue, investorEntityApi } from '@yieldstreet/platform-kit';
import { Formik, Form } from 'formik';
import YSLogo from '@yieldstreet/ui-kit/assets/logos/black_ys_logo.svg';
import { INVESTOR_ENTITY_KYB_PROVIDER_CONFIG, INVESTOR_ENTITY_KYC_PROVIDER_CONFIG } from './providers';
import { useRouteInvestorEntity, useInvestorEntityRoute } from '../../utils';
import ToggleTooltip from 'sharedComponents/ToggleTooltip';
import { useKYCNextStep } from './utils';
import { KYCValidationSchema, testIds, copy } from './KYC.model';
import { TooltipWrapper, SParagraph } from './KYC.style';
export const KYC = () => {
  var _investorEntityStatus;
  const history = useHistory();
  const {
    stringifiedVersions,
    isLoading: isLoadingDisclosureVersions
  } = useConsentDisclosureVersions();
  const {
    investorEntityId,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const identityStatus = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity;
  const identityProvider = identityStatus === null || identityStatus === void 0 ? void 0 : identityStatus.provider;
  const accountType = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus = investorEntityStatus.type) === null || _investorEntityStatus === void 0 ? void 0 : _investorEntityStatus.value;
  const isEntity = accountType === InvestorEntityTypeValue.ENTT;
  const providerObject = isEntity ? INVESTOR_ENTITY_KYB_PROVIDER_CONFIG : INVESTOR_ENTITY_KYC_PROVIDER_CONFIG;
  const {
    name: providerName,
    logo: providerLogo,
    route: providerRoute
  } = identityProvider && providerObject[identityProvider] || {};
  const [updateInvestorAccount, updateAccountResult] = investorEntityApi.useUpdateInvestorEntityMutation();
  const errorMessage = useApiErrorMessage(updateAccountResult);
  const submitDisclosures = useCallback(() => {
    updateInvestorAccount({
      investorEntityId,
      consentedDisclosures: [{
        content: consentDisclosureText,
        version: stringifiedVersions
      }]
    });
  }, [stringifiedVersions, updateInvestorAccount, investorEntityId]);
  const {
    isLoading: isUpdateLoading,
    isSuccess
  } = updateAccountResult;
  const {
    isLoading: isNextStepLoading,
    redirectToNextStep
  } = useKYCNextStep(true);
  const identityProviderPath = useInvestorEntityRoute(providerRoute);
  useEffect(() => {
    if (isSuccess && identityProviderPath) {
      history.push(identityProviderPath);
    }
  }, [isSuccess, history, identityProviderPath]);
  const identityStatusContent = useMemo(() => {
    if (identityStatus) {
      const text = copy === null || copy === void 0 ? void 0 : copy[identityStatus.status];
      if (!text) {
        return null;
      }
      switch (identityStatus.status) {
        case InvestorEntityStatus.CONFIRMED:
          return __jsx(ProcessInfo, {
            testId: testIds.statusSuccess.id,
            icon: __jsx(StatusIcon, {
              status: ProcessStatus.SUCCESS,
              testId: testIds.icon.id
            }),
            title: text.title,
            buttons: __jsx(Button, {
              trackingId: testIds.continueButton.id,
              buttonType: "primary",
              onClick: redirectToNextStep,
              loading: isNextStepLoading,
              disabled: isNextStepLoading
            }, text.cta)
          }, __jsx(Paragraph, {
            secondary: true
          }, text.text));
        case InvestorEntityStatus.PENDING:
          return __jsx(ProcessInfo, {
            testId: testIds.statusPending.id,
            icon: __jsx(StatusIcon, {
              status: ProcessStatus.PENDING,
              testId: testIds.icon.id
            }),
            title: text.title,
            buttons: __jsx(Button, {
              trackingId: testIds.contactIrButton.id,
              buttonType: "secondary",
              onClick: () => {
                var _Intercom, _ref;
                return (_Intercom = (_ref = window).Intercom) === null || _Intercom === void 0 ? void 0 : _Intercom.call(_ref, 'show');
              }
            }, text.cta)
          }, __jsx(Paragraph, {
            secondary: true
          }, text.text));
        case InvestorEntityStatus.INVALID:
          return __jsx(ProcessInfo, {
            testId: testIds.statusInvalid.id,
            icon: __jsx(StatusIcon, {
              status: ProcessStatus.FAILURE,
              testId: testIds.icon.id
            }),
            title: text.title,
            buttons: __jsx(Button, {
              trackingId: testIds.contactIrButton.id,
              buttonType: "basic",
              onClick: () => {
                var _Intercom2, _ref2;
                return (_Intercom2 = (_ref2 = window).Intercom) === null || _Intercom2 === void 0 ? void 0 : _Intercom2.call(_ref2, 'show');
              }
            }, text.cta)
          }, __jsx(Paragraph, {
            secondary: true
          }, text.text));
        case InvestorEntityStatus.FAILED:
          return __jsx(ProcessInfo, {
            testId: testIds.statusFailed.id,
            icon: __jsx(StatusIcon, {
              status: ProcessStatus.FAILURE,
              testId: testIds.icon.id
            }),
            title: text.title,
            buttons: __jsx(Button, {
              trackingId: testIds.contactIrButton.id,
              buttonType: "basic",
              onClick: () => {
                var _Intercom3, _ref3;
                return (_Intercom3 = (_ref3 = window).Intercom) === null || _Intercom3 === void 0 ? void 0 : _Intercom3.call(_ref3, 'show');
              }
            }, text.cta)
          }, __jsx(Paragraph, {
            secondary: true
          }, text.text));
        case InvestorEntityStatus.ACTION_NEEDED:
          return __jsx(ProcessInfo, {
            testId: testIds.statusActionNeeded.id,
            icon: __jsx(StatusIcon, {
              status: ProcessStatus.WARNING,
              testId: testIds.icon.id
            }),
            title: text.title,
            buttons: __jsx(Button, {
              trackingId: testIds.continueButton.id,
              buttonType: "primary",
              onClick: () => identityProviderPath && history.push(identityProviderPath)
            }, text.cta)
          }, __jsx(Paragraph, {
            marginBottom: "m",
            secondary: true
          }, text.text), __jsx(Paragraph, {
            secondary: true
          }, text.text_additional));
        default:
          return null;
      }
    }
  }, [identityStatus, redirectToNextStep, isNextStepLoading, identityProviderPath, history]);
  if (!identityProvider || isLoadingDisclosureVersions || isNextStepLoading) {
    return __jsx(PageLoader, {
      testId: testIds.loader.id
    });
  }
  if (identityStatusContent) {
    return __jsx(PageFlow, {
      smallHeader: true,
      showLogo: false
    }, __jsx(Wrapper, null, identityStatusContent));
  }
  return __jsx(PageFlow, {
    smallHeader: true,
    showLogo: true
  }, __jsx(Wrapper, {
    marginTop: {
      tablet: 'm'
    }
  }, providerName && providerLogo && __jsx(Formik, {
    initialValues: {
      legalAgreement: false
    },
    validationSchema: KYCValidationSchema,
    onSubmit: submitDisclosures
  }, formikProps => __jsx(Form, null, __jsx(ProcessInfo, {
    testId: testIds.externalProviderInfo.id,
    iconCentered: true,
    icon: __jsx(ExternalProviderVisual, {
      fromIcon: {
        alt: 'Yieldstreet',
        icon: YSLogo
      },
      toIcon: {
        alt: providerName,
        icon: providerLogo
      },
      showArrow: true
    }),
    title: copy[providerName].title,
    buttons: __jsx(Button, {
      type: "submit",
      trackingId: testIds.startButton.id,
      buttonType: "primary",
      loading: isUpdateLoading,
      disabled: isUpdateLoading
    }, copy[providerName].cta)
  }, __jsx(Paragraph, {
    secondary: true
  }, copy[providerName].text), __jsx(SParagraph, {
    secondary: true
  }, copy[providerName].text_additional), __jsx(Wrapper, {
    marginTop: "m",
    marginHorizontal: {
      tablet: 'l'
    }
  }, __jsx(FormCheckboxInput, {
    name: "legalAgreement",
    label: __jsx(Paragraph, {
      size: "small"
    }, __jsx(Markdown, {
      parserOptions: {
        commonmark: true
      },
      source: consentDisclosureText,
      linkTarget: "_blank"
    })),
    formikProps: formikProps,
    testId: testIds.legalCheckbox.id
  })), errorMessage && __jsx(Wrapper, {
    marginTop: "m"
  }, __jsx(Notification, {
    description: errorMessage,
    severity: "error",
    testId: testIds.errorNotification.id
  }))))), __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
    stepLabel: copy.tooltip.title,
    stepInfo: copy.tooltip.text
  }))));
};