import { InvestorEntityProvider } from '../../enums';
export const investorAccountProvider = {
  type: InvestorEntityProvider.YS,
  data: null
};
export const investorAccountProviderETC = {
  type: InvestorEntityProvider.ETC,
  data: {
    etc_owner_contact_id: '2200044',
    etc_account_number: '205350023'
  }
};