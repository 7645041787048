import { InvestorEntityStatus, investorEntityApi, AccreditationType } from '@yieldstreet/platform-kit';
import { AppAction, appActionManager } from '../../../app-actions';
import { useInvestorEntity } from '../../../hooks';
export const getInvestorAccreditationAppAction = (investorEntityStatus, aiqData) => {
  var _aiqData$, _aiqData$2;
  const {
    id: investorEntityId,
    accreditation,
    accreditedSignUp
  } = investorEntityStatus;
  const accreditationType = (_aiqData$ = aiqData[0]) === null || _aiqData$ === void 0 ? void 0 : _aiqData$.accreditationType;
  const accreditationId = (_aiqData$2 = aiqData[0]) === null || _aiqData$2 === void 0 ? void 0 : _aiqData$2.id;
  if (!accreditedSignUp) {
    return null;
  }
  switch (accreditation.status) {
    case InvestorEntityStatus.MISSING:
      return appActionManager.getAction(AppAction.SUBMIT_ACCREDITATION, {
        investorEntityId
      });
    case InvestorEntityStatus.PENDING:
      switch (accreditationType) {
        case AccreditationType.THIRD_PARTY:
          return appActionManager.getAction(AppAction.EDIT_ACCREDITATION_THIRD_PARTY, {
            investorEntityId,
            accreditationId
          });
        case AccreditationType.NET_WORTH:
          return appActionManager.getAction(AppAction.EDIT_ACCREDITATION_NET_WORTH, {
            investorEntityId,
            accreditationId
          });
        case AccreditationType.LICENSED_BROKER:
          return appActionManager.getAction(AppAction.EDIT_ACCREDITATION_LICENSED_BROKER, {
            investorEntityId,
            accreditationId
          });
        default:
          /**
           * Default is INCOME
           */
          return appActionManager.getAction(AppAction.EDIT_ACCREDITATION, {
            investorEntityId,
            accreditationId
          });
      }
    case InvestorEntityStatus.ACTION_NEEDED:
      switch (accreditationType) {
        case AccreditationType.THIRD_PARTY:
          return appActionManager.getAction(AppAction.UPDATE_ACCREDITATION_DOCUMENTATION_THIRD_PARTY, {
            investorEntityId,
            accreditationId
          });
        case AccreditationType.NET_WORTH:
          return appActionManager.getAction(AppAction.UPDATE_ACCREDITATION_DOCUMENTATION_NET_WORTH, {
            investorEntityId,
            accreditationId
          });
        case AccreditationType.LICENSED_BROKER:
          return appActionManager.getAction(AppAction.UPDATE_ACCREDITATION_DOCUMENTATION_LICENSED_BROKER, {
            investorEntityId,
            accreditationId
          });
        default:
          /**
           * Default is INCOME
           */
          return appActionManager.getAction(AppAction.UPDATE_ACCREDITATION_DOCUMENTATION, {
            investorEntityId,
            accreditationId
          });
      }
    case InvestorEntityStatus.FAILED:
    case InvestorEntityStatus.ATTENTION:
      if (accreditationType === AccreditationType.INCOME) {
        return appActionManager.getAction(AppAction.RENEW_ACCREDITATION_INCOME, {
          investorEntityId
        });
      } else {
        return appActionManager.getAction(AppAction.RENEW_ACCREDITATION, {
          investorEntityId
        });
      }
    default:
      return null;
  }
};
export const useInvestorAccreditationAppAction = investorEntityId => {
  const {
    isLoading: isLoadingEntityData,
    investorEntityStatus
  } = useInvestorEntity(investorEntityId);
  const {
    isLoading: isLoadingAIQData,
    data: aiqData = []
  } = investorEntityApi.useGetInvestorEntityAiqQuery({
    investorEntityId
  });
  const isLoading = isLoadingEntityData || isLoadingAIQData;
  const accreditationAppAction = investorEntityStatus && getInvestorAccreditationAppAction(investorEntityStatus, aiqData);
  return {
    isLoading,
    accreditationAppAction
  };
};