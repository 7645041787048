import { InvestorEntityType, InvestorEntityStatus, InvestorEntityStepProvider } from '@yieldstreet/platform-kit';
import { ProviderMigrationStatus } from './useProviderMigration.model';
export const getMigrationStatus = (investorEntity, investorEntityStatus, kycDetails, bankLinkDetails) => {
  const {
    type: {
      entityType,
      iraAccountType
    },
    kycProvider
  } = investorEntity !== null && investorEntity !== void 0 ? investorEntity : {};
  const {
    bankAccounts
  } = investorEntityStatus !== null && investorEntityStatus !== void 0 ? investorEntityStatus : {};

  // If not eligible for KYC migration
  if (!kycDetails) {
    // IRAs are not in the plans for KYC migration
    if (iraAccountType || entityType === InvestorEntityType.IRA || kycProvider !== InvestorEntityStepProvider.YS) {
      return ProviderMigrationStatus.NOT_APPLICABLE;
    }

    // Only if entity set status to coming soon
    return ProviderMigrationStatus.COMING_SOON;
  }

  // If eligible for KYC migration
  const {
    identity
  } = kycDetails;

  // If Unit application not created yet, migration still not triggered
  if (identity.provider === InvestorEntityStepProvider.YS) {
    return ProviderMigrationStatus.KYC_REQUIRED;
  }
  switch (identity.status) {
    // If identity approved
    case InvestorEntityStatus.CONFIRMED:
      // If not eligible to bank linking migration (in case of no previously linked bank accounts), migration is complete
      if (!bankLinkDetails) {
        return ProviderMigrationStatus.COMPLETED;
      }

      // If eligible for bank linking migration
      const {
        plaidLinkedBankAccounts,
        defaultBankAccount
      } = bankLinkDetails;

      // If already linked at least 1 bank account and micro deposit flow has started, migration is pending
      if (plaidLinkedBankAccounts && !defaultBankAccount && (bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.status) === InvestorEntityStatus.PENDING) {
        return ProviderMigrationStatus.PENDING_MICRO_DEPOSIT;
      }

      // If already linked at least 1 bank account and default selected, migration is complete
      if (plaidLinkedBankAccounts && defaultBankAccount) {
        return ProviderMigrationStatus.COMPLETED;
      }
      if (!plaidLinkedBankAccounts) {
        // Otherwise, bank linking is required to complete migration
        return ProviderMigrationStatus.BANK_LINK_REQUIRED;
      }

      // If default bank account not selected
      return ProviderMigrationStatus.DEFAULT_BANK_REQUIRED;

    // If Unit application completed (could be approved or rejected)
    case InvestorEntityStatus.FAILED:
      return ProviderMigrationStatus.KYC_FAILED;

    // Unit application is created, by KYC details still incomplete
    case InvestorEntityStatus.MISSING:
      return ProviderMigrationStatus.KYC_INCOMPLETE;

    // Unit application is pending manual review, investor cannot continue KYC right now
    case InvestorEntityStatus.PENDING:
      return ProviderMigrationStatus.KYC_PENDING;

    // Unit application status indicates a problem
    default:
      return ProviderMigrationStatus.KYC_ERROR;
  }
};
export const isMigrationStarted = status => status !== ProviderMigrationStatus.KYC_REQUIRED;
export const isMigrationActionable = status => [ProviderMigrationStatus.KYC_REQUIRED, ProviderMigrationStatus.KYC_INCOMPLETE, ProviderMigrationStatus.KYC_FAILED, ProviderMigrationStatus.KYC_ERROR, ProviderMigrationStatus.BANK_LINK_REQUIRED].includes(status);
export const isMigrationEligible = status => isMigrationActionable(status) || [ProviderMigrationStatus.KYC_PENDING, ProviderMigrationStatus.COMPLETED].includes(status);
export const isMigrationFinal = status => [ProviderMigrationStatus.NOT_APPLICABLE, ProviderMigrationStatus.COMING_SOON, ProviderMigrationStatus.COMPLETED].includes(status);
export const isMigrationProblem = status => [ProviderMigrationStatus.KYC_FAILED, ProviderMigrationStatus.KYC_ERROR].includes(status);