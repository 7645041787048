import { InvestorEntityProvider } from '@yieldstreet/platform-kit';
export const InvestorEntityProviderTagMatchers = {
  [InvestorEntityProvider.ETC]: (_ref, _ref2) => {
    let {
      etc_owner_contact_id: userContactId,
      etc_account_number: userAccountNumber
    } = _ref;
    let {
      etc_owner_contact_id: investorContactId,
      etc_account_number: investorAccountNumber
    } = _ref2;
    return userContactId === investorContactId && userAccountNumber === investorAccountNumber;
  }
};