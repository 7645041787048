import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { IdentityDocumentType, InvestorEntityActionCode, InvestorEntityStatus, InvestorEntityType, InvestorEntityTypeValue } from '../../enums';
import { InvestorEntityStepProvider } from '../../types';
const investorEntity1 = {
  id: 349913,
  name: '20193 Wealth Tr Cody Wisozk IRA account',
  type: 'TRADITIONAL_IRA',
  typeDescription: 'Traditional YSIRA',
  status: {
    userId: 4,
    id: 349913
  }
};
const investorEntity2 = {
  id: 1,
  name: 'Delpha Towne',
  type: 'INDIVIDUAL',
  typeDescription: 'Individual',
  status: {
    userId: 4,
    id: 1
  }
};
const investorEntity3 = {
  id: 346822,
  name: 'Entity Tester',
  type: 'LLC',
  typeDescription: 'Limited Liability Company',
  status: {
    userId: 4,
    id: 346822
  }
};
export const investorEntityStatus = {
  userId: 365647,
  investorEntityId: 303150,
  investorEntityStatusId: 1,
  readyToInvest: false,
  setupComplete: false,
  accreditedSignUp: true,
  type: {
    identityDocumentType: IdentityDocumentType.SSN,
    iraAccountType: null,
    entityType: null,
    name: InvestorEntityType.INDIVIDUAL,
    value: InvestorEntityTypeValue.ENTT
  },
  identity: {
    status: InvestorEntityStatus.CONFIRMED,
    statusDisplay: 'Confirmed',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.FOOTPRINT
  },
  accreditation: {
    status: InvestorEntityStatus.MISSING,
    statusDisplay: 'Unverified',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.YS
  },
  bankAccounts: {
    status: InvestorEntityStatus.MISSING,
    statusDisplay: 'None',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.PLAID
  },
  investorEntityAction: {
    investorEntityId: null,
    code: InvestorEntityActionCode.LINK_BANK_ACCOUNT,
    status: null,
    nextStep: 'Link a Bank Account',
    priority: 100
  },
  id: 303150,
  nextStep: 'Link a Bank Account',
  provider: {
    status: InvestorEntityStatus.CONFIRMED,
    statusDisplay: 'Confirmed',
    pendingActions: [],
    message: null,
    entityType: null,
    provider: InvestorEntityStepProvider.PLAID
  },
  allPendingActions: [InvestorEntityActionCode.SET_KYC_1]
};
export const allInvestorEntityStatusApiResponse = {
  success: true,
  investorEntity: [investorEntity1, investorEntity2, investorEntity3]
};
export const allInvestorEntityStatusTransformResult = {
  [investorEntity1.id]: _objectSpread(_objectSpread({}, investorEntity1.status), {}, {
    id: investorEntity1.id,
    type: investorEntity1.type,
    typeDescription: investorEntity1.typeDescription
  }),
  [investorEntity2.id]: _objectSpread(_objectSpread({}, investorEntity2.status), {}, {
    id: investorEntity2.id,
    type: investorEntity2.type,
    typeDescription: investorEntity2.typeDescription
  }),
  [investorEntity3.id]: _objectSpread(_objectSpread({}, investorEntity3.status), {}, {
    id: investorEntity3.id,
    type: investorEntity3.type,
    typeDescription: investorEntity3.typeDescription
  })
};