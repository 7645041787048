var __jsx = React.createElement;
import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { PageContainer } from '@yieldstreet/ui-kit';
import { getRoute, useAllInvestorEntities, useProviderMigration } from '@yieldstreet/tool-kit';
import { InvestorEntityStatus, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { DefaultBank, DefaultBankButtonAction, DefaultBankScreenType } from 'sharedComponents/DefaultBank';
import { ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW_SET_DEFAULT } from '../investorEntity/screens/Management/routes/BankAccounts/BankAccounts.model';
import { FIRST_INVESTMENT_LANDING_FULL_PATH } from '../firstInvestmentLanding';
import { getInvestorEntityRoute } from '../investorEntity/utils';
import { useDefaultBankScreenType } from '../../sharedComponents/DefaultBank/hooks/useDefaultBankScreenType';
import { BankLinking } from './BankLinking';
import { BANK_FLOW_LINK_A_BANK_ACCOUNT, BANK_FLOW_LINK_A_BANK_ACCOUNT_DEFAULT } from './BankLinkingPageFlow.model';
import { INVESTOR_ENTITY_MANAGEMENT_PATH } from '../investorEntity/InvestorEntityContainer.model';
import { CREATE_ACCOUNT_ROOT_PATH } from '../newCreateInvestorAccount/CreateInvestorAccount.model';
export const BankLinkingPageFlow = () => {
  const params = useParams();
  const history = useHistory();
  const {
    refetchBankLinkStatus,
    refetchKYCStatus
  } = useProviderMigration();
  const {
    managementStrategy,
    investorEntityId
  } = params;
  const screenType = useDefaultBankScreenType(investorEntityId);
  const {
    investorEntityStatuses
  } = useAllInvestorEntities();
  const investorEntityStatus = investorEntityStatuses === null || investorEntityStatuses === void 0 ? void 0 : investorEntityStatuses[investorEntityId];
  useEffect(() => {
    var _investorEntityStatus;
    if ((investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus = investorEntityStatus.identity) === null || _investorEntityStatus === void 0 ? void 0 : _investorEntityStatus.status) !== InvestorEntityStatus.CONFIRMED) {
      history.push(getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MANAGEMENT_PATH));
    }
    if (screenType === DefaultBankScreenType.MicroDeposit) {
      history.push(getInvestorEntityRoute(investorEntityId, FIRST_INVESTMENT_LANDING_FULL_PATH));
    }
  }, [screenType, investorEntityStatus, investorEntityId, history]);
  const refreshMigrationState = useCallback(() => {
    refetchBankLinkStatus();
    refetchKYCStatus();
  }, [refetchBankLinkStatus, refetchKYCStatus]);
  const handleSuccess = useCallback(() => {
    refreshMigrationState();
    history.push(getInvestorEntityRoute(investorEntityId, BANK_FLOW_LINK_A_BANK_ACCOUNT_DEFAULT, managementStrategy));
  }, [refreshMigrationState, history, investorEntityId, managementStrategy]);
  const location = useLocation();
  const handleAction = useCallback(action => {
    refreshMigrationState();
    switch (action) {
      case DefaultBankButtonAction.CHANGE_DEFAULT_BANK_CANCEL:
      case DefaultBankButtonAction.DEFAULT_BANK_LINK_ANOTHER_BANK:
        history.push(getInvestorEntityRoute(investorEntityId, ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW_SET_DEFAULT, managementStrategy));
        break;
      default:
        // Here we are assuming that we always will link to the citizenship
        // step post bank-linking when in a `Discretionary` flow.
        if (managementStrategy === PortfolioStrategyValues.Discretionary) {
          history.push(getRoute(CREATE_ACCOUNT_ROOT_PATH, {
            accountType: 'individual',
            managementStrategy: managementStrategy || PortfolioStrategyValues.Self,
            investorEntityId,
            step: 'citizenship'
          }));
        } else {
          history.push(getInvestorEntityRoute(investorEntityId, FIRST_INVESTMENT_LANDING_FULL_PATH));
        }
        break;
    }
  }, [refreshMigrationState, history, investorEntityId, managementStrategy]);
  return __jsx(PageContainer, {
    background: "dark"
  }, __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    path: BANK_FLOW_LINK_A_BANK_ACCOUNT,
    render: () => __jsx(BankLinking, {
      investorEntityId: investorEntityId,
      onLinkSuccess: handleSuccess
    }),
    exact: true
  }), __jsx(Route, {
    path: BANK_FLOW_LINK_A_BANK_ACCOUNT_DEFAULT,
    render: () => __jsx(DefaultBank, {
      investorEntityId: investorEntityId,
      onAction: handleAction
    }),
    exact: true
  })));
};